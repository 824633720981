import cn from 'classnames';
import { ReactComponent as Logo } from '../../assets/connex-logo-dark-theme.svg';
import styled from 'styled-components';
import style from './style';
import '../../css/homer.css';
const Styled = styled.div`
  ${style}
`;
const MainPage = props => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', background: '#202124' }}>
      <Styled className={cn('header')}>
        <Logo height={125}></Logo>
      </Styled>
      <div style={{ width: '100%', height: '1px', backgroundColor: 'gray' }}></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          height: '81vh',
        }}
      >
        <div id="homer">
          <div class="head">
            <div class="hair1"></div>
            <div class="hair2"></div>
            <div class="body head-top"></div>
            <div class="no-border body head-main"></div>
            <div class="no-border m1"></div>
            <div class="no-border m2"></div>
            <div class="no-border m3"></div>
            <div class="no-border m4"></div>
            <div class="no-border neck1"></div>
            <div class="body neck2"></div>
            <div class="body ear">
              <div class="no-border inner1"></div>
              <div class="no-border inner2"></div>
              <div class="no-border body clip"></div>
            </div>
            <div class="mouth">
              <div class="mouth5"></div>
              <div class="mouth2"></div>
              <div class="mouth1"></div>
              <div class="mouth7"></div>
              <div class="no-border mouth3"></div>
              <div class="no-border mouth4"></div>
              <div class="no-border mouth6"></div>
              <div class="no-border mouth8"></div>
            </div>
            <div class="right-eye">
              <div class="no-border right-eye-pupil"></div>
              <div class="no-border body eyelid-top"></div>
              <div class="no-border body eyelid-bottom"></div>
            </div>
            <div class="body nose"></div>
            <div class="body nose-tip"></div>
            <div class="left-eye">
              <div class="no-border left-eye-pupil"></div>
              <div class="no-border body eyelid-top"></div>
              <div class="no-border body eyelid-bottom"></div>
            </div>
          </div>
        </div>
        <h2 style={{ color: 'GrayText' }}>UI WORKING CORRECTLY!</h2>
      </div>
    </div>
  );
};

export default MainPage;
