import { css } from 'styled-components';

export default css`
  background-color: #202124;
  width: 100%;
  padding: 1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
